function escapeHtml(text) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

function formMenuURL(url, attributeID, attributeValue)
{
    return '/category' + (url[0] != '/' ? '/' : '') + url +
        (attributeID ? ('/' + attributeID + '/' + encodeURIComponent(attributeValue)) : '');
}

function renderBrands() {
    menuOption.brands.forEach(function (brand) {
        $('#brand_menu').append('<li><a href="/brands/'+brand.ID+'">'+escapeHtml(brand.name)+'</a></li>');
    })
    $('#brand_menu').append('<li><a class="see_all_brands" href="/brands">See All Brands</a></li>');
}

function renderSubCategories (top, attributes) {
    var html = '';

    if (top['brands'] && Object.keys(top['brands']).length) {
        html += '<ul class=""><li class=""><span>Top<br/> Brands</span></li>';
        Object.values(top['brands']).forEach(function (brand) {
            html += '<li class=""><a href="/brands/' + brand['ID'] + '">' + escapeHtml(brand['name']) + '</a></li>';
        });
        html += '</ul>';
    }

    var attrs = attributes;
    if (attrs[top['ID']]) {
        attributes = attrs[top['ID']];
    }
    if (top['categories'] && !Object.keys(top['categories']).length) {
        html += '<ul class="'+ (Object.keys(attributes).length > 0 ? 'dropdown-menu' : '') + '">';
        Object.values(attributes).forEach(function (attr) {
            var shtml = '';
            if (attr['values'] && Object.keys(attr['values']).length) {
                shtml += '<ul class="' + (Object.keys(attr['values']).length > 0 ? 'dropdown-menu' : '') + '">';
                Object.values(attr['values']).forEach(function (value) {
                    shtml += '<li class=""><a href="' + formMenuURL(top['url'], attr['ID'], value) + '">'
                        + escapeHtml(value)
                        + '</a></li>';
                });
                shtml += '</ul>';
            }
            if (shtml) {
                html += '<li class=""><a href="javascript:;">' + escapeHtml(attr['attribute']) + '</a>';
                html += shtml;
                html += '</li>';
            }
        });
        html += '</ul>';
    }
    else {
        top['categories'] = Object.values(top['categories']);
        var j = 0;
        html += '<ul class="' + (Object.keys(top['categories'][j]).length > 0 ? 'dropdown-menu' : '') + '">';
        while (top['categories'][j]) {
            var cat = top['categories'][j];
            if (!cat['categories']) cat['categories'] = [];
            var catattrs = attrs[cat['ID']] ? attrs[cat['ID']] : [];
            var countSubMenuItems = Object.keys(cat['categories']).length + Object.keys(catattrs).length;
            j += 1;
            html += '<li class="' + (countSubMenuItems ? 'dropdown-submenu' : 'empty-sub-menu') + '"><a href="' +
                formMenuURL(cat['url']) + '">' + escapeHtml(cat['category']) + '</a>';
            html += '<ul class="' + (countSubMenuItems ? 'dropdown-menu' : '') + '">';
            Object.values(cat['categories']).forEach( function (sub) {
                var subattrs = attrs[sub['ID']] ? attrs[sub['ID']] : [];
                var shtml = '';
                if (subattrs) {
                    Object.values(subattrs).forEach(function (attr) {
                        if (attr['values'] && Object.keys(attr['values']).length) {
                            shtml += '<li class="dropdown-submenu"><a href="javascript:;">' +
                                escapeHtml(attr['attribute']) + '</a><ul class="dropdown-menu options">';
                            shtml += '<li class=""><span class="submenu_title">Options</span></li>';
                            Object.values(attr['values']).forEach( function (value) {
                                shtml += '<li class=""><a href="' + formMenuURL(sub['url'], attr['ID'], value) + '">' +
                                escapeHtml(value) + '</a></li>';
                            });
                            shtml += '</ul></li>';
                        }
                    });
                }
                html += '<li class="' + (shtml ? 'dropdown-submenu' : 'empty-sub-menu') +'">' +
                    '<a href="' + formMenuURL(sub['url']) + '">' + escapeHtml(sub['category']) + '</a>';
                html += '<ul class="dropdown-menu">' + shtml + '</ul>';
                html += '</li>';
            });
            if (Object.keys(cat['categories'])) {
                html += '<li style="border-bottom: 1px solid #eeeeee;"></li>';
            }
            Object.values(catattrs).forEach(function (attr) {
                if (Object.keys(attr['values']).length) {
                    html += '<li class="dropdown-submenu"><a href="javascript:;">' +
                        escapeHtml(attr['attribute']) + '</a>';
                    html += '<ul class="dropdown-menu options">';
                    html += '<li class=""><span class="submenu_title">Options</span></li>';
                    Object.values(attr['values']).forEach(function (value) {
                        html += '<li class="">' +
                            '<a href="' + formMenuURL(cat['url'], attr['ID'], value) + '">' + escapeHtml(value) +
                            '</a></li>';
                    });
                    html += '</ul></li>';
                }
            });
            html += '</ul>';
            html += '</li>';
        }
        html += '</ul>';
    }

    return html;
}

function renderCategories() {
    var categoryCount = 0;
    Object.values(menuOption.categories).forEach(function (category) {
        if (categoryCount<1) {
            Object.values(category.categories).forEach(function (top) {
                if (top['categories'] || top['brands'] || top['attributes']) {
                    $('#category_' + top.ID).append(renderSubCategories(top, category.attributes));
                }
            });
        }
        else {
            // do not render extra data
            return;
        }
        categoryCount++;
    });
}

function renderMenu() {
    renderBrands();
    renderCategories();
}

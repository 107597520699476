$(document).ready(function() {

  var $menu = $('ul.dropdown-menu');
  var cssMenu = $('#cssmenu');

  function activateSubmenu(row) {
    var $row = $(row);
    var $submenu = $row.find("> ul");
    var incTop = row.top + 35;
    $row.addClass('open');
    $submenu.css('opacity', 0)
      .css('display', 'block')
      .css('top', incTop)
      .animate({
        'opacity': 1,
        'top': row.top
      }, 30);
  }

  function deactivateSubmenu(row) {
    var $row = $(row),
      $submenu = $row.find(">ul, li.open>ul");
    $submenu.css('display', 'none');
    if (!($(row).find(':hover').length)) refreshMenuAim($submenu);
    $(row).removeClass('open').find('li.open').removeClass('open');
  }

  function deactivateMenu() {
    cssMenu.find('li.open').removeClass('open').find('ul').css('display', 'none');
    refreshMenuAim($menu);
  }

  function refreshMenuAim($el) {
    $el.menuAim('destroy')
      .menuAim({
        activateCallback: activateSubmenu,
        deactivateCallback: deactivateSubmenu,
        exitMenuCallback: deactivateMenu
      });
  }

  $menu.menuAim({
    activateCallback: activateSubmenu,
    deactivateCallback: deactivateSubmenu,
    exitMenuCallback: deactivateMenu
  });

});

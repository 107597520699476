var slider, $bxViewport;
$(document).ready(function () {
  $('.search_btn').click(function () {
    var type = $('select[id^=searchType]').val();
    if (type == 'system') {
      ajax.doit('->selectOEMSystem');
      return;
    }
    var text = $.trim($('.bluebar .textfield.keyword').val());
    ajax.doit('->logSearchQuery', text).onload = function () {
      window.location.assign('/search/' + encodeURIComponent(type) + '/' + encodeURIComponent(text));
    };

  });
  eventName = ($.browser.opera || $.browser.mozilla) ? 'keypress' : 'keydown';

  $('.bluebar .textfield').keypress(function (event) {
    if (event.keyCode == 13) {
      var type = $('select[id^=searchType]').val();
      if (type == 'system') {
        if (selectByEnter) {
          selectByEnter = false;
          return;
        }
      }
      $('.search_btn').focus();
      setTimeout(function () {
        $('.search_btn').click();
      }, 500);

    }

  });

  $('.smallSearch').keypress(function (event) {
    if (event.keyCode == 13) {
      var text = $.trim($('.smallSearch').val());
      ajax.doit('->logSearchQuery', text).onload = function () {
        window.location.assign('/search/all/' + encodeURIComponent(text));
      };
    }
  });

  $('select[id^=searchType]').change(function () {
    if ($(this).val() == 'system') {
      $('.fieldsForSystem').removeClass('disNone');
      $('.fieldsForOther').addClass('disNone');
    } else {
      $('.fieldsForSystem').addClass('disNone');
      $('.fieldsForOther').removeClass('disNone');
    }
  });

  $(".bluebar select").msDropdown({roundedBorder: false, enableAutoFilter: false});
  $('.top_menu').slicknav();
  slider = $('.bxslider').bxSlider({
    auto: true,
    infiniteLoop: true,
    speed: 1000,
    adaptiveHeight: true,
    onSliderLoad: function () {
      $bxViewport = $('.bx-viewport');
      changeYTPSize();
      $(window).resize(function () {
        changeYTPSize()
      });
    }
  });
});

var menuOffset = 0;
var buttonOffset = 0;

function getURLVar(v)
{
  return unescape(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + escape(v).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

function showAccordeonTab(tab)
{
  $.scrollTo({top: $(tab).offset().top - 50}, 500, { axis: 'y' });
}

$(document).ready(function()
{
  b2b_dropdown_height();
  /* search bar */
  $('.search_button').click(function()
  {
    var type = {'All': 'all', 'Category': 'category', 'Part #': 'name', 'SKU': 'sku', 'System': 'system'}[$('.search_option_button').html()];
    var text = $.trim($('.header_search .textfield').val());
    //if (text == '') return;
    ajax.doit('->logSearchQuery', text, getURLVar('token')).onload = function() {
        if ($('.header_search').css('position') == 'static')
        {
            var token = getURLVar('token'), limit = getURLVar('limit'), category = getURLVar('category');
            window.location.assign('/b2b?type=' + encodeURIComponent(type) + '&keyword=' + encodeURIComponent(text) + (token ? '&token=' + encodeURIComponent(token) : '') + (category ? '&category=' + encodeURIComponent(category) : '') + (limit ? '&limit=' + encodeURIComponent(limit) : ''));
        }
        else window.location.assign('/search/' + encodeURIComponent(type) + '/' + encodeURIComponent(text));
    };
  });
  
  $('.header_search .textfield').keypress(function(event)
  {
    if (event.keyCode == 13) $('.search_button').click();
    if (!$('.search_option_button').hasClass('open')) $('.search_option_button').click();
  });
  
	 $('.search_option_button').click(function()
  {
			 $(this).toggleClass('open');
			 $('.search_options').slideToggle('fast');
			 return false;
		});
	 $('.search_options li a').click(function()
  {
			 var param = $(this).text();
			 $('.search_option_button').text(param);
			 $('.search_options').hide();
			 $('.search_option_button').removeClass('open');
			 return false;
		});
  $('body').bind('click', function()
  {
			 $('.search_options').hide();
    $('.search_option_button').removeClass('open');
		});
  
  /* accordeon filter */
	$('body').on('click', '.accordeon_trigger', function()
  {
			 $(this).toggleClass('open');
			 $(this).parent('.accordeon').find('.options_list').toggle(function()
    {
					 $(this).parent('.accordeon').toggleClass('open');
				});
		});
  
  /* tabs of the product page */
 	$('.tab_trigger').bind('click', function()
  {
		  var target = $(this).attr('id');
		  $(this).parents('.tabs_triggers_list').find('.active').removeClass('active');
		  $(this).addClass('active');
		  $(this).parents('.tabs_container').find('.tab_container').hide();
		  $('#p' + target.substr(3)).show();
                  if (target.substr(3) == 7) {
                      ajax.doit('->setProductTab', target.substr(3));
                      $('#p7 .chosen-container').width('200px');
                  }
		  return false;
	 });
  
  /* focus in and focus out for suppoer page. */
  var els = $('input,textarea');
  els.on('focus', '.textfield', function(){$(this).addClass('focus')});
  els.on('blur', '.textfield', function(){$(this).removeClass('focus')});
	
	var openSelectList = false;
	
    $('.custom_select_field').bind('click', function(e){
        e.stopPropagation();  
		
		if(openSelectList==true){
			$('.custom_select ul').css({'display':'none'})
		}
        $(this).parent().find('ul').css({'width':$(this).parent().outerWidth()-2, 'display':'block'});
        openSelectList = true; 

		var tmp_this = $(this);
        $(this).parent().find('ul li').bind('click', function(){
            $(this).parent().css({'display':'none'});
			$(this).parents('.custom_select').find('.custom_select_field').text($(this).text());
			$(this).parents('.custom_select').find('.custom_select_field').addClass('selected');
			 
			if (tmp_this.parent('.custom_select').is('.next_step_trigger')){
				var next_step = tmp_this.parents('.form_line').next('.form_line');
				if (next_step.is('.hidden')){
					next_step.css({'opacity': 0});
					next_step.removeClass('hidden');
					next_step.animate({'opacity': 1}, 500);
				}
			}
			 
			 
        });
		
		
    });
	
	$('body').bind('click', function(){
		if(openSelectList==true){
			$('.custom_select ul').css({'display':'none'})
		}
	});
	
	$('a.button.option_trigger').bind('click',
		function(){	
			var target = $(this).attr('href');
			$(this).parent('.input_options_trigger').hide();
			$(target).show().removeClass('hidden');
			return false;
		}
	);
	
	$('.input_option .button_back').bind('click',
		function(){
			$(this).parents('.input_option').parent().find('.input_options_trigger').show();
			$(this).parents('.input_option').hide().addClass('hidden');
		}
	);
	
    $('.autocomplete_field_brand').autocomplete({
        ajaxSource: '->getBrandsForSelect2',
        ajaxCallback: '->selectBrand',
        minLength: 0,
        delay: 0,
    });
    
    $('.autocomplete_field_system').autocomplete({
        ajaxSource: '->getSystemsForSelect2',
        ajaxCallback: '->selectSystem',
        minLength: 0,
        delay: 0,
    });
});

$(window).scroll(
    function(){
        if (!$('#tab7').hasClass('active')) return;
        var target = $('.category_triggers_list');
        var target2 = $('.b2b_sidebar_wrapper');
        if (target2.length == 0 || target.length == 0) return;
        if (menuOffset == 0){
            menuOffset = target.offset().top;
        }
        if (buttonOffset == 0){
            buttonOffset = target.offset().top;
        }
        
        var bodyPos = $(window).scrollTop();
        //alert (bodyPos);
        if (bodyPos >= menuOffset) {
            target.addClass('fixed_triggers');
            target2.addClass('fixed_b2b_sidebar_button');
        } else {
            target.removeClass('fixed_triggers');
            target2.removeClass('fixed_b2b_sidebar_button');
        }

        b2b_dropdown_height();
        
    }
);

function b2b_dropdown_height(){
	/* determine b2b_dropdown_filter height */
	if ($('.b2b_sidebar_wrapper').length == 0) return;
	var b2b_dropdown_filter_offsetTop = $('.b2b_sidebar_wrapper').offset().top + parseInt($('.b2b_dropdown_filter').css('top'));
    var window_height = $(window).height();
    if ($('.b2b_sidebar_wrapper').is('.fixed_b2b_sidebar_button')){
        var b2b_dropdown_filter_height = window_height - 10;
    } else {
        var b2b_dropdown_filter_height = window_height - b2b_dropdown_filter_offsetTop - 10;
    }
    
    if (b2b_dropdown_filter_height < 600 ) {
        b2b_dropdown_filter_height = 600;
    }
    
	$('.b2b_dropdown_filter').css('max-height', b2b_dropdown_filter_height+'px');
		
	/* end determine b2b_dropdown_filter height */
}

function addArrtToUrl(param, value)
{
    addAttrsToUrl(param + '=' + value);
}

/**
 * Adds variables to URL from backend
 * @param string
 */
function addAttributesToUrl(string) {
    addAttrsToUrl(string);
}

function addAttrsToUrl(string)
{
    var params = paramsArr = newparams = newparamsArr = pairs = [];
    if (document.location.search) {
        paramsArr = document.location.search.substr(1).split('&');
        for (var i = 0; i < paramsArr.length; i++){
            params[paramsArr[i].split('=')[0]] = paramsArr[i].split('=')[1];
        }
    }
    newparamsArr = string.split('&');
    for (var i = 0; i < newparamsArr.length; i++){
        newparams[newparamsArr[i].split('=')[0]] = newparamsArr[i].split('=')[1];
    }
    for (key in newparams) {
        if (newparams[key] == undefined || newparams[key] == '' || newparams[key] == 0 || newparams[key] == null)
            delete params[key];
        else params[key] = newparams[key];
    }
    
    for (key in params) {
        pairs.push(key + '=' + params[key]);
    }
    
    history.replaceState(null, null, document.location.pathname + '?' + pairs.join('&'));
} 


// script for responsive
mbMenuST = {'containers':'.header .navigation', 'firstItem': 'Menu', 'indent':'-'}

// Main functionality
jQuery(document).ready(function() {
	var siteclass = window.location.pathname.substr(1).split('#')[0];
	jQuery('body').addClass('st_'+siteclass);
    // Menu containers array
    jQuery('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />');
    var menuContainers = mbMenuST.containers.replace(/, /g,',').split(',');
    if ( '' == menuContainers )
        return false;
    jQuery.each(menuContainers, function( index, container ) {
        var ul = jQuery(container).find('ul').first();
        jQuery('<select />', {
            'class': 'jquery-responsive-select-menu jrsm-' + index,
            'name': 'jrsm-' + index
        }).insertAfter(ul);

        if ( !mbMenuST.firstItem )
            var label = 'Navigation';
        jQuery('<label />', {
            'text': label,
            'class': 'jrsm-label',
            'for': 'jrsm-' + index
        }).insertAfter(ul);
        select = jQuery(container).find('.jquery-responsive-select-menu');
        if ( mbMenuST.firstItem ) {
            var firstOption = jQuery('<option />', {
                'class': 'first-option',
                'value'   : '',
                'text'    : mbMenuST.firstItem
            }).appendTo(select);
        }
        get_child_menu_items( ul, 1 );
        if ( 1 == mbMenuST.showCurrentPage )
            select.find('.current-page').prop('selected',true);
        else
            select.find('option').first().prop('selected',true);

    }); // End 1. Main loop through menu containers

    // Select functionality
    jQuery('.jquery-responsive-select-menu').change(function() {
        var option = jQuery(this).find('option:selected').is('[value]');

        // Check if option has actual value
        if ( jQuery(this).find('option:selected').is('[value]') && "#" != jQuery(this).find('option:selected').val() ) {
            window.location = jQuery(this).find('option:selected').val();
        }
    });
    $(mbMenuST.containers).append('<span class="mb-menu-icon">Menu</span>');

    jQuery('.fixed_sidebar').append(jQuery('<span class="filter-close">Close</span>').click(function () {
          jQuery('.fixed_sidebar').hide();
    }));
    jQuery('<span class="filter-open">Show filters</span>').click(function () {
          jQuery('.fixed_sidebar').show();
    }).insertBefore( jQuery('.fixed_sidebar') );


    // script for iframe page
    var siteScale = jQuery(window).width()/960;
    if(jQuery('#page').length > 0 && jQuery(window).width() <960){
    	jQuery('body').css('transform','scale(' +siteScale+')').css('transform-origin','0 0 0');
    }
    if(jQuery('.st_newproduct').length > 0 && jQuery(window).width() <960){
    	jQuery('.st_newproduct table').css('width',960).css('transform','scale(' +siteScale+')').css('transform-origin','0 0 0');
    }
});

function get_child_menu_items( ul, depth ) {
    jQuery.each( ul.children('li'), function( index, li ) {
        var li = jQuery(li);
        var prefix = mbMenuST.indent;
        prefix = Array(depth).join(prefix);
        var e = li.find('a').first();
        var value = e.attr('href'); 
        var text = e.text();
        if(text != "" && value != ""){

          if ( ! mbMenuST.hideEmptyLinks || ( mbMenuST.hideEmptyLinks && value && "#" != value ) ) {
              var option = jQuery('<option />', {
                  'value'   : value,
                  'text'    : prefix + ' ' + text
              }).appendTo(select);
              if ( mbMenuST.disableEmptyLinks && ( ! value || "#" == value ) ) {
                  option.prop('disabled', true);
              }
          }
          if(value == "javascript:;"){
               option.prop('disabled', true);
          }
          if ( li.hasClass('current_page_item') || li.hasClass('current-menu-item') ) {
              option.addClass('current-page');
          }
          if(depth < 2){
	          if(depth == 1){
	            var ul = li.find('ul.simple_submenu');
	          }else{
	            var ul = li.find('ul.options_submenu_list');
	          }
	          if ( ul.length > 0 ) {
	            ul.each(function () {
	              get_child_menu_items( jQuery(this), depth + 1 );
	            })
	          }
	      }
        }
    }); // End 2. Loop through menu item <li>'s
}

$.extend($.ui.autocomplete.prototype, {
    _init: function() {
        var self = this;
        
        self.$scrollOptions = {
            isAjaxLoadingInProgress: false,
            isLastItem: false,
            page: 1
        };
        
        self.source = function (request, response) {
            $.ajax({
                type: 'GET',
                url: '?' + $.param({
                    ajaxfunc: self.options.ajaxSource,
                    ajaxkey: encodeURIComponent(document.body.id),
                    query: request.term,
                    limit: 30,
                    pg: self.$scrollOptions.page
                }),
                success: function (data) {
                    var items = $.map(data.items, function (item) {
                        return {
                            value: item.id,
                            label: item.text
                        };
                    });
                    
                    response(items);
                }
            });
        };
        
        self.element.on('autocompleteselect', function(event, ui) {
            $(this).val(ui.item.label);
            ajax.doit(self.options.ajaxCallback, ui.item.value);
            return false;
        });
        
        self.element.focus(function () {
            self.$scrollOptions.page = 1;
            self.$scrollOptions.isLastItem = false;
            $(this).autocomplete('search');
        });
    },
    _renderMenu: function (ul, items) {
        var self = this;
            
        $.each(items, function (index, item) {
            self._renderItemData(ul, item);
        });
 
        function isScrollbarBottom(container) {
            var height = container.outerHeight();
            var scrollHeight = container[0].scrollHeight;
            var scrollTop = container.scrollTop();
            if (scrollTop >= scrollHeight - height) {
                return true;
            }
            return false;
        }
 
        $(ul).unbind('scroll');
        $(ul).scroll(function () {
            if (isScrollbarBottom($(ul)) && !self.$scrollOptions.isLastItem) {
                if (!self.$scrollOptions.isAjaxLoadingInProgress) {
                    self.$scrollOptions.isAjaxLoadingInProgress = true;
                    
                    self.source({ term: self.element.val() }, function(items) {
                        self.$scrollOptions.isAjaxLoadingInProgress = false;
                        if (items.length < 30) {
                            self.$scrollOptions.isLastItem = true;
                        }
                    
                        ul.find('li.autocomplete_li_loading_message').remove();
                    
                        $.each(items, function (index, item) {
                            self._renderItemData(ul, item);
                            
                            self.menu.refresh();
                            
                            ul.show();
                            
                            self._resizeMenu();
                            
                            ul.position($.extend({
                                of: self.element
                            }, self.options.position));
                            if (self.options.autoFocus) {
                                self.menu.next(new $.Event('mouseover'));
                            }
                        });
                    });
                    
                    self.$scrollOptions.page++;
                    
                    $('<li class="autocomplete_li_loading_message" />')
                        .text('Loading...')
                        .css({
                            background: '#ccc',
                            width: '100%'
                        })
                        .appendTo(ul);
                }
                return;
            }
        });
    },
});

/**
 * Init left menu Options [x] button for WidgetSearchFilter
 */

function initOptions () {
    var callback = $('#filterOptionsCallBack').val();
    if (!callback) {
        return;
    }
    $('.fixed_sidebar_inner select, .filter_block select').unbind("change").on('change', function (evt, params) {
        if (params['group'] == 'attributes') {
            ajax.doit(callback, params['group'], params['ID'], params['attrvalue']);
        } else {
            ajax.doit(callback, params['group'], params['ID']);
        }
    });
}

/**
 * Cart manipulation from backend
 * @type {{selectorWrapper: string, updateLine: CartWrapper.updateLine, wrapper: CartWrapper.wrapper}}
 */
var CartWrapper = {
    selectorWrapper: '.header_inner-cart',
    selectorLine: '.quantity',
    updateLine: function (line) {
        this.wrapper().find(this.selectorLine).html(line);
    },
    wrapper: function () {
        return $(this.selectorWrapper)
    }
};
